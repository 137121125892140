@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
        url('../fonts/roboto-bold-webfont.woff') format('woff');
    font-display: block;
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-light-webfont.woff2') format('woff2'),
        url('../fonts/roboto-light-webfont.woff') format('woff');
    font-display: block;
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
        url('../fonts/roboto-regular-webfont.woff') format('woff');
    font-display: block;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Texgyreheros';
    src: url('../fonts/texgyreheros-bold.otf') format('opentype'),
        url('../fonts/texgyreheros-bold-webfont.woff2') format('woff2'),
        url('../fonts/texgyreheros-bold-webfont.woff') format('woff'),
        url('../fonts/texgyreheros-bold-webfont.ttf') format('truetype');
    font-display: block;
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?msbqp0');
    src: url('../fonts/icomoon.eot?msbqp0#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?msbqp0') format('truetype'),
        url('../fonts/icomoon.woff?msbqp0') format('woff'),
        url('../fonts/icomoon.svg?msbqp0#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-weight:before {
    content: '\e900';
}
.icon-user:before {
    content: '\e901';
}
.icon-time:before {
    content: '\e902';
}
.icon-sofa-1:before {
    content: '\e903';
}
.icon-sms:before {
    content: '\e904';
}
.icon-phone:before {
    content: '\e905';
}
.icon-icon-btn:before {
    content: '\e906';
}
.icon-facebook:before {
    content: '\e907';
}
.icon-breathable:before {
    content: '\e908';
}
.icon-body:before {
    content: '\e909';
}
.icon-back:before {
    content: '\e90a';
}
.icon-arrow-right-small:before {
    content: '\e90b';
}
.icon-arrowstroke:before {
    content: '\e90c';
}
.icon-arrowlong:before {
    content: '\e90d';
}
.icon-angle-down:before {
    content: '\e90e';
}
