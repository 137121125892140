body {
    font-family: "Roboto";
    font-weight: 300;
    font-style: normal;
    color: #333;
}
.overflow-wrap {
    display: block;
    position: relative;
    overflow: hidden;
}
.container_right {
    width: calc(100% / 9 * 8);
    margin-right: 0;
    margin-left: auto;
}
.container_narrow {
    width: calc(100% / 9 * 7);
    margin-right: auto;
    margin-left: auto;
}
@media all and (max-width: 900px) {
    .container_right,
    .container_narrow {
        width: 100%;
    }
}

.col {
    padding: 0 10px;
}
